#root {
    $color: #000;
}

.inputStyled {
    font-size: 10px;
    appearance: none;
    outline: none;
    background-color: transparent;
    color: var(--color);
}

.inputStyled::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color);
}
.inputStyled::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color);
}
.inputStyled:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--color);
}
.inputStyled:-moz-placeholder {
    /* Firefox 18- */
    color: var(--color);
}
